import * as React from "react"
import SEO from "components/seo"
import { ClientsT, PageContextT, AvailablePositionT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import PositionDetail from "../components/organisms/PositionDetail"

type Props = {
  pageContext: PageContextT<AvailablePositionT>
  clients: ClientsT
}

const IndexPage: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      configurations,
      mobileHeaderLinks,
      uploadedFiles,
    },
  } = props

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={[]}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />
      <PositionDetail
        position={page}
        configurations={configurations}
        uploadedFiles={uploadedFiles}
      />
    </Layout>
  )
}

export default IndexPage
