import React from "react"
import styled from "styled-components"
import Block from "../atoms/Block"
import BlockTitle from "../atoms/BlockTitle"
import { rem } from "styles/mixins/rem"
import { colors, mediaQueries } from "styles/variables"
import {
  AvailablePositionT,
  PageT,
  BreadcrumbsT,
  ConfigurationT,
  UploadedFileT,
} from "types/Common"
import WysiwygRenderer from "components/atoms/WysiwygRenderer"
import Banner from "../molecules/Banner"
import ConfigurationWithIcon from "../molecules/ConfigurationWithIcon"
import ResponsiveImage from "../atoms/ResponsiveImage"
import { ContactForm } from "../PageBuilder/Components/ContactForm"
import Button from "../atoms/Button"
import Breadcrumbs from "../molecules/Breadcrumbs"
import { useTranslation } from "react-i18next"

type PropsT = {
  position: AvailablePositionT & PageT
  breadcrumbs?: BreadcrumbsT
  configurations: [ConfigurationT]
  uploadedFiles: [UploadedFileT]
}

const Perex = styled.p`
  font-size: ${rem(22)};
  text-align: center;
  margin-bottom: 3rem;
  ${mediaQueries.smMin} {
    font-size: ${rem(24)};
    text-align: left;
    margin-bottom: 2.5rem;
  }
`

const EmployeeContact = styled.div`
  ${mediaQueries.xlMin} {
    min-width: 33.5rem;
  }
`
const Title = styled(BlockTitle)`
  margin-bottom: 0.5rem;
  color: #ffffff;
`

const Subtitle = styled.p``

const Buttons = styled.div`
  ${mediaQueries.smMin} {
    grid-template-columns: 14rem 14rem;
    justify-items: start;
    margin-left: 2rem;
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  background-color: #fbfbfb;
  margin-top: 0.5rem;

  ${mediaQueries.mdMin} {
    position: relative;
    height: 0;
    margin-top: 0;
    z-index: 1000;
    background-color: transparent;
    color: white;

    a {
      color: ${colors.primary};
    }
  }
`

const PositionDetail = ({
  configurations,
  uploadedFiles,
  position: {
    description,
    employee,
    form,
    coverImage,
    title,
    subtitle,
    perex,
    location,
    jobType,
  },
}: PropsT) => {
  const { t, i18n } = useTranslation()
  const breadcrumbs = [
    {
      title: t("career"),
      route: i18n.language === "en" ? "/en/career" : "/kariera",
    },
    {
      title: t("availablePositions"),
    },
    {
      title,
    },
  ]

  const contactRef = React.useRef<HTMLDivElement>(null)
  const executeScroll = () => contactRef.current.scrollIntoView()

  return (
    <div>
      <div className="flex flex-col-reverse md:flex-col">
        <StyledBreadcrumbs items={breadcrumbs} />
        <Banner background={coverImage} wideText={true}>
          <Title title={title} type="h1" align="left"></Title>
          <Subtitle className={"text-3xl sm:text-4xl font-bold mb-14"}>
            {subtitle}
          </Subtitle>
          <ul className={"flex"}>
            <li className={"text-lg mr-5 lg:mr-12 flex items-center"}>
              <span className="icon-address text-white text-2xl mr-4 relative top-0.5"></span>
              {location}
            </li>
            <li className={"text-lg flex items-center"}>
              <span className="icon-clock text-white text-2xl mr-4 relative top-0.5"></span>
              {jobType}
            </li>
          </ul>
        </Banner>
      </div>
      <Block background="gray">
        <div className={"lg:flex lg:items-start md:px-4 lg:px-8"}>
          <div className="lg:mr-auto">
            <Perex>{perex}</Perex>
            <WysiwygRenderer content={description} />
          </div>
          <EmployeeContact
            className={
              "grid justify-items-center px-4 py-8 sm:px-8 sm:pb-10 lg:ml-16 xl:mr-8 bg-white shadow-lg lg:sticky lg:top-0 lg:-mt-40 z-20"
            }
          >
            <BlockTitle title={t("contactUs")}></BlockTitle>
            <ResponsiveImage
              image={employee.image}
              formats={["original"]}
              fallbackFormat={"original_jpeg"}
              width={173}
              height={173}
            />
            <p className={"text-2xl font-black mt-5 mb-4"}>{employee.name}</p>
            <ConfigurationWithIcon
              iconColor={"text-quantcomBlue"}
              icon={"phone"}
              value={employee.phone}
              textClasses={"text-black"}
            />
            <ConfigurationWithIcon
              iconColor={"text-quantcomBlue"}
              icon={"email"}
              value={employee.email}
              textClasses={"text-black"}
            />
            <Button
              className={"mt-10"}
              iconAnimated
              icon="arrow-right-short"
              onClick={executeScroll}
            >
              {t("jobApply")}
            </Button>
          </EmployeeContact>
        </div>
      </Block>

      {form && (
        <ContactForm
          contactRef={contactRef}
          configurations={configurations}
          uploadedFiles={uploadedFiles}
          socialLinks={configurations}
          componentProps={{
            contactForm: form.id,
            title: t("interestedInOffer"),
            subtitle: t("letUsKnow"),
            phone: 123123123,
            email: "email@email.cz",
          }}
          contactForms={[form]}
        />
      )}
      <Block>
        <div className={"grid justify-items-center pt-24 mb-20 border-t "}>
          <Title title={t("notApplicablePosition")}></Title>
          <p className={"text-xl text-center"}>{t("checkOthers")}</p>
          <Buttons
            className={
              "grid grid-cols-1 sm:grid-cols-2 gap-2 justify-items-center mt-16"
            }
          >
            <Button
              iconAnimated
              icon="arrow-right-short"
              link={
                i18n.language === "en"
                  ? "/en/career#open-positions"
                  : "/kariera#open-positions"
              }
            >
              {t("otherAvailablePositions")}
            </Button>
            <Button
              iconAnimated
              icon="arrow-right-short"
              link={"#contact-form"}
            >
              {t("contactUs")}
            </Button>
          </Buttons>
        </div>
      </Block>
    </div>
  )
}

export default PositionDetail
