import { Link } from "gatsby"
import React from "react"
import { BreadcrumbsT } from "types/Common"
import Container from "../atoms/Container"
import styled from "styled-components"
import { colors, mediaQueries } from "styles/variables"

type PropsT = {
  items: BreadcrumbsT
  overlap?: boolean
  className?: string
}

const List = styled.ul`
  margin: 0.7rem 0 1rem;
  display: flex;
  ${mediaQueries.smMax} {
    margin-bottom: 0;
  }
`

const ListItem = styled.li`
  line-height: 1.2;
  ${mediaQueries.mdMin} {
    font-size: 20px;
  }

  &:not(:first-child) {
    padding-left: 1.1em;
    position: relative;
    &::before {
      content: ">";
      padding: 0 0.3em;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a {
    color: ${colors.secondary};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Breadcrumbs = ({ items, overlap, className }: PropsT) => (
  <Container className={className}>
    <List $overlap={overlap}>
      {items.map((item, index) => {
        return (
          <ListItem key={item.title}>
            {item.route ? (
              <Link to={item.route}>{item.title}</Link>
            ) : (
              <span>{item.title}</span>
            )}
          </ListItem>
        )
      })}
    </List>
  </Container>
)

export default Breadcrumbs
